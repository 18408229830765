import type { Component, Page } from "@bloomreach/spa-sdk";
import { getFileName } from "mkm-avengers";

export function useKitchenList(page: Page, component: Component) {
  const { data, getSrcOf, getLinkOf } = useContentHelpers(page, component);

  return {
    data,
    kitchens: data?.kitchenitems.map((kitchen: any) => {
      const imageSrc = getSrcOf(kitchen.image);
      return {
        title: kitchen.title,
        description: kitchen.description,
        image: imageSrc,
        alt: getFileName(imageSrc),
        link: getLinkOf(kitchen.link),
        colors: kitchen.coloritems.map((color: any) => color.color?.selectionValues?.[0]?.key),
      };
    }),
  };
}
