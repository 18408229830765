<template>
  <div class="ui-text-charcoal-default ui-w-full" data-test-id="table">
    <div
      v-if="header"
      class="ui-border-t-sm ui-border-grey-default ui-py-xs ui-px-sm"
    >
      <Heading size="xs">
        {{ header.title }}
      </Heading>
      <Text weight="semi" class="ui-text-grey-darker">
        {{ header.description }}
      </Text>
    </div>
    <div
      :class="[
        'ui-overflow-x-auto',
        route.path.includes('my-account') && 'table-container',
      ]"
    >
      <table class="w-full">
        <thead
          class="ui-hidden md:ui-table-header-group ui-font-semi ui-text-sm ui-border-y-sm ui-border-y-grey-default ui-bg-grey-lighter"
        >
          <tr>
            <th
              v-for="(item, idx) in fields"
              :key="idx"
              :class="[
                'ui-text-left ui-font-semi ui-py-[11.5px] first-of-type:ui-pl-sm ui-leading-sm',
                (sortable || item.isSortable) &&
                  'ui-cursor-pointer hover:ui-bg-grey-light',
                item.isWide && 'ui-w-1/4',
              ]"
              :colspan="rowSelector && idx === 0 ? 2 : 1"
              @click="() => sortBy(item.key)"
            >
              <Stack gap="2xs" align="center">
                <span
                  :class="[
                    sortColumn === item.key &&
                      item.isSortable &&
                      'ui-font-bold',
                  ]"
                >
                  {{ item.label }}
                </span>
                <span v-if="sortable || item.isSortable">
                  <Icon
                    :size="14"
                    name="arrow-up"
                    :class="[
                      'ui-text-mkm-blue-default',
                      sortColumn === item.key && sortDirection === 'asc'
                        ? 'ui-rotate-0'
                        : 'ui-rotate-180',
                    ]"
                  />
                </span>
              </Stack>
            </th>
            <th v-if="slots.actions" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in dataToDisplay"
            :key="index"
            :class="[
              'md:h-[75px] ui-relative ui-border-b-sm first-of-type:ui-border-y-sm ui-border-grey-default ui-text-sm ui-py-2xs md:ui-py-2xs [ ui-flex ui-flex-col md:ui-table-row ]',
              item.selected && rowSelector && 'ui-bg-grey-lighter',
              (item.link || rowSelector) &&
                'hover:ui-bg-grey-lighter ui-cursor-pointer',
            ]"
            :data-test-id="rowDataTestId"
          >
            <td v-if="rowSelector" @click="rowSelected(item)">
              <div
                :class="[
                  'ui-absolute ui-left-xs ui-top-[20px] md:ui-relative md:ui-pl-sm md:ui-left-none md:ui-top-none',
                  (item.link || rowSelector) && 'ui-cursor-pointer ',
                ]"
              >
                <Checkbox
                  :id="`row-${index}`"
                  data-test-id="rowSelector"
                  :model-value="item.selected"
                  class="md:-ui-mr-sm"
                />
              </div>
            </td>
            <td
              v-for="(field, idx) in fields"
              :key="idx"
              :class="[
                rowSelector && 'ui-pl-xs',
                idx === 0 && !rowSelector && 'md:!ui-pl-sm',
              ]"
              @click="rowClick(item)"
            >
              <NuxtLink
                v-if="item[field.key] || slots[field.key]"
                :data-test-id="field.testId"
                :to="item.link"
                :class="[
                  'md:!ui-block',
                  'ui-py-2xs md:ui-py-2xs ui-text-base [ ui-grid md:ui-table-cell ] ui-leading-sm md:ui-leading-base',
                  idx === 0 ? 'md:ui-font-bold' : 'md:ui-font-semi',
                  rowSelector
                    ? 'ui-px-md md:ui-px-none'
                    : 'ui-px-xs md:ui-px-none',
                  hideMobileLabels ? 'ui-grid-cols-1' : 'ui-grid-cols-2',
                ]"
              >
                <span
                  v-if="!hideMobileLabels"
                  class="md:ui-hidden ui-font-bold ui-text-base"
                >
                  {{ field.label }}
                </span>
                <span v-if="!slots[field.key]" :item="item">
                  {{ item[field.key] }}
                </span>
                <slot v-else :name="field.key" :item="item" />
              </NuxtLink>
            </td>
            <td
              v-if="slots.actions"
              class="md:min-w-lg ui-py-2xs ui-absolute md:ui-static ui-right-xs md:ui-py-2xs ui-text-base ui-leading-sm md:ui-leading-base"
            >
              <slot name="actions" :item="item" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="slots.pagination">
      <slot name="pagination" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Checkbox from "../FormElements/Checkbox/Checkbox.vue";
import Heading from "../Typography/Heading/Heading.vue";
import Icon from "../Icon/Icon.vue";
import Stack from "../Layout/Stack/Stack.vue";
import { Item, TableProps } from "./tableTypes";
import { defineProps, defineEmits, useSlots, ref, computed } from "vue";
import { useWindowSize } from "../../composables/useWindowSize";
import { useRoute } from "vue-router";

type ComparableValue = string | number | Date;

const props = defineProps<TableProps>();

const slots = useSlots();
const sortColumn: any = ref<string | null>(null);
const sortDirection = ref<"asc" | "desc">("asc");

const route = useRoute();

const emit = defineEmits<{
  (e: "rowSelected", item: Item): void;
  (e: "rowClick", item: Item): void;
}>();

const rowSelected = (item: Item) => {
  item.selected = !item.selected;
  emit("rowSelected", item);
};

const rowClick = (item: Item) => emit("rowClick", item);

const sortBy = (key: string) => {
  if (sortColumn.value === key) {
    sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
  } else {
    sortColumn.value = key;
    sortDirection.value = "asc";
  }
};

const isValidDate = (value: ComparableValue) => {
  if (typeof value !== "string") return false;

  const dateRegex = /^(?:\d{1,2}\/){2}\d{4}$/;
  return dateRegex.test(value);
};

const parseDate = (dateString: string): Date | null => {
  if (!dateString) {
    return null;
  }

  const parts = dateString.split("/");
  if (parts.length !== 3) {
    return null;
  }

  const day = Number.parseInt(parts[0], 10);
  const month = Number.parseInt(parts[1], 10) - 1;
  const year = Number.parseInt(parts[2], 10);

  if (Number.isNaN(day) || Number.isNaN(month) || Number.isNaN(year)) {
    return null;
  }

  return new Date(year, month, day);
};

const parseCurrency = (value: string) => {
  const numericValue = value.replace(/[^\d.-]+/g, "");
  return Number.parseFloat(numericValue);
};

const isCurrency = (value: string) => {
  return /^-?£\d{1,3}(,\d{3})*(\.\d{1,2})?$/.test(value);
};

const applySortDirection = (comparisonResult: number) => {
  return comparisonResult * (sortDirection.value === "asc" ? 1 : -1);
};

const compareDates = (aValue: string, bValue: string): number => {
  const dateA = parseDate(aValue);
  const dateB = parseDate(bValue);

  if (!dateA && !dateB) return 0;
  if (!dateA) return applySortDirection(-1);
  if (!dateB) return applySortDirection(1);

  return applySortDirection(dateA.getTime() - dateB.getTime());
};

const compareCurrencies = (aValue: string, bValue: string): number => {
  const currencyA = parseCurrency(aValue);
  const currencyB = parseCurrency(bValue);
  return applySortDirection(currencyA - currencyB);
};

const compareGeneralValues = (
  aValue: ComparableValue,
  bValue: ComparableValue,
): number => {
  if (aValue === bValue) return 0;
  return applySortDirection(aValue > bValue ? 1 : -1);
};

const compareValues = (
  aValue: ComparableValue,
  bValue: ComparableValue,
): number => {
  const bothAreStrings =
    typeof aValue === "string" && typeof bValue === "string";

  if (bothAreStrings && isValidDate(aValue) && isValidDate(bValue)) {
    return compareDates(aValue, bValue);
  }

  if (bothAreStrings && isCurrency(aValue) && isCurrency(bValue)) {
    return compareCurrencies(aValue, bValue);
  }

  return compareGeneralValues(aValue, bValue);
};

const shouldSort = () => {
  return (
    sortColumn.value &&
    (props.sortable || props.fields.some((field) => field.isSortable))
  );
};

const sortedData = computed(() => {
  if (!shouldSort()) return props.data;

  return [...props.data].sort((a, b) => {
    const aValue = a[sortColumn.value];
    const bValue = b[sortColumn.value];

    return compareValues(aValue, bValue);
  });
});

const dataToDisplay = computed((): Array<Item> => {
  if (!props.page || !props.itemsPerPage) return sortedData.value;

  const start = (props.page - 1) * props.itemsPerPage;
  const end = start + props.itemsPerPage;

  return sortedData.value.slice(start, end);
});

const { width } = useWindowSize();

const tableWidth = computed(() =>
  width.value < 1024 ? "100%" : `${width.value - 442}px`,
);
</script>

<style scoped>
.table-container {
  width: v-bind(tableWidth) !important;
}
</style>
