import type { Maybe } from "@vue-storefront/unified-data-model";

import useMiddlewareCheck from "./useMiddlewareCheck";
import { useGA4EcommerceEvents } from "mkm-gtm";
import useBranches from "./useBranches";

type TallyDetails = {
  unit: string;
  length: number;
  sizes: {
    quantity: number;
    length: number;
  }[];
};

export type CartLineItem = {
  id: string;
  sku: string;
  name: string;
  quantity: number;
  image_url: string;
  availability_flag: number;
  is_dropship: boolean;
  is_tally: boolean;
  tally: TallyDetails;
  type: string;
  price: number;
  price_inc_vat: number;
  unit: string;
  offer_price: number;
  offer_price_inc_vat: number;
  offer_unit: string;
  subtotal: number;
  subtotal_inc_vat: number;
  product_id: string;
  error: unknown[];
};

export type Cart = {
  id: string;
  branch_id: string;
  customer_id: string | null;
  email: string;
  line_items: CartLineItem[];
  totals: {
    subtotal: number;
    vat: number;
    total: number;
  };
  currency: string;
  error: unknown[];
  merged: boolean;
  type: number;
  empty: boolean;
};

export interface UseCartState {
  cart: Maybe<Cart>;
  merged: boolean;
  shouldRefetchCart: boolean;
  userPostcode: string;
  fetchPromises: any;
  error: unknown;
  loadingCart: boolean;
}
interface LoadingState {
  changeCartItemQuantity: boolean;
  skus: string[];
}

const findItemInCart = (itemsInCart: any[], sku: string) => {
  return itemsInCart.find((item) => item.sku === sku);
};

const findItem = (itemsInCart: any[], sku: string) => {
  return itemsInCart.find((i) => i.sku === sku);
};

const useCart = () => {
  const { user } = useUser();
  const { selectedBranch } = useBranches();
  const customerCategory = user.value?.account.business_type.split("|");
  const { pushGA4EcommerceEvent, GA4EcommerceEvents } = useGA4EcommerceEvents();

  const state = useState<UseCartState>(`useCart`, () => ({
    cart: null,
    merged: false,
    shouldRefetchCart: false,
    fetchPromises: [],
    userPostcode: "",
    error: {},
    loadingCart: false,
    changeCartItemQuantity: false,
  }));

  const loading = useState<LoadingState>("useCart-loading", () => ({
    changeCartItemQuantity: false,
    skus: [],
  }));

  const fireRemoveFromCartGAEvent = async (item: any, quantity: number) => {
    await pushGA4EcommerceEvent(GA4EcommerceEvents.REMOVE_FROM_CART, {
      logged_in: user.value !== null || false,
      user_id: user.value?.id ?? null,
      customer_category_1: customerCategory?.[0] === "NTrade" ? "NON-TRADE" : "TRADE",
      customer_category_2: customerCategory?.[1] ?? null,
      customer_category_3: customerCategory?.[2] ?? null,
      account_owning_branch: null,
      account_type: user.value?.account.type ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
      price_ex_vat: item?.price ?? null,
      stock_type: item?.is_dropship ? "DROPSHIP" : "BRANCH STOCKED",
      ecommerce: {
        currency: "GBP",
        value: item?.price_inc_vat ?? null,
        items: [
          {
            item_id: item?.sku ?? null,
            item_name: item?.name ?? null,
            bigcommerce_item_id: item?.product_id.toString() ?? null,
            price: item?.price_inc_vat ?? null,
            price_ex_vat: item?.price ?? null,
            quantity: quantity,
          },
        ],
      },
    });
  };

  const fireAddToCartGAEvent = async (item: any, quantity: number) => {
    await pushGA4EcommerceEvent(GA4EcommerceEvents.ADD_TO_CART, {
      logged_in: user.value !== null || false,
      customer_category_1: customerCategory?.[0] === "NTrade" ? "NON-TRADE" : "TRADE",
      customer_category_2: customerCategory?.[1] ?? null,
      customer_category_3: customerCategory?.[2] ?? null,
      account_type: user.value?.account.type ?? null,
      account_owning_branch: user.value?.account.branch_id ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
      price_ex_vat: item?.offer_price ?? null,
      stock_type: item?.is_dropship ? "DROPSHIP" : "BRANCH STOCKED",
      ecommerce: {
        currency: "GBP",
        value: item?.offer_price_inc_vat ?? null,
        items: [
          {
            item_id: item?.sku ?? null,
            bigcommerce_item_id: item?.product_id.toString() ?? null,
            item_name: item?.name ?? null,
            price: item?.offer_price_inc_vat ?? null,
            price_ex_vat: item?.offer_price ?? null,
            quantity: quantity,
          },
        ],
      },
    });
  };

  const middlewareCheck = useMiddlewareCheck();
  const cart = useState<Cart | null>("useCart-cart", () => ({ empty: true }) as Cart);
  const physicalItems = computed(() => {
    return state.value.cart?.line_items || [];
  });
  const upsertLoadingSku = (sku: string) => {
    const skuAlreadyLoading = loading.value.skus.find((loadingSku: any) => loadingSku === sku);

    if (skuAlreadyLoading) {
      return;
    }

    loading.value.skus = [...loading.value.skus, sku];
  };

  const removeLoadingSku = (sku: string) => {
    loading.value.skus = loading.value.skus.filter((loadingSku) => loadingSku !== sku);
  };

  const getPhysicalItems = async () => {
    if (state.value.cart?.empty) {
      return [];
    }
    return physicalItems.value;
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const removeCartItem = async (sku: any) => {
    const itemsInCart = await getPhysicalItems();
    const item = itemsInCart.find((i) => i.sku === sku);
    const lineId = item?.id;

    const payload = {
      lineId: lineId as string,
    };
    try {
      loading.value.changeCartItemQuantity = true;
      const { data: cartData, error } = await useAsyncData("deleteCartItem", () =>
        useSdk().mkmAPI.deleteCartItem(payload),
      );

      await pushGA4EcommerceEvent(GA4EcommerceEvents.REMOVE_FROM_CART, {
        logged_in: user.value !== null || false,
        user_id: user.value?.id ?? null,
        customer_category_1: customerCategory?.[0] === "NTrade" ? "NON-TRADE" : "TRADE",
        customer_category_2: customerCategory?.[1] ?? null,
        customer_category_3: customerCategory?.[2] ?? null,
        account_owning_branch: user.value?.account.branch_id ?? null,
        account_type: user.value?.account.type ?? null,
        selected_branch: selectedBranch.value?.name ?? null,
        price_ex_vat: item?.price ?? null,
        stock_type: item?.is_dropship ? "DROPSHIP" : "BRANCH STOCKED",
        ecommerce: {
          currency: "GBP",
          value: item?.price_inc_vat ?? null,
          items: [
            {
              item_id: item?.sku ?? null,
              item_name: item?.name ?? null,
              bigcommerce_item_id: item?.product_id.toString() ?? null,
              price: item?.price_inc_vat ?? null,
              price_ex_vat: item?.price ?? null,
              quantity: itemsInCart.length,
            },
          ],
        },
      });

      if (error.value) {
        state.value.error = error.value;
      } else {
        state.value.cart = cartData.value ?? state.value.cart;
      }
    } catch (error) {
      state.value.error = error;
    } finally {
      loading.value.changeCartItemQuantity = false;
    }
  };

  const fetchCart = async () => {
    if (state.value.loadingCart) {
      state.value.shouldRefetchCart = true;

      return new Promise<void>((resolve) => {
        state.value.fetchPromises.push(resolve);
      });
    }

    state.value.loadingCart = true;

    const { data: cartData, error } = await useAsyncData("getCart", () => useSdk().mkmAPI.getCart());

    useHandleError(error.value);

    state.value.cart = cartData.value ?? state.value.cart;

    if (cartData.value.merged) state.value.merged = true;

    state.value.loadingCart = false;
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const addCartItemQuantity = async (sku: any, quantity: any, tally?: string | undefined) => {
    // Check if cart item quantity change is already in progress
    if (loading.value.changeCartItemQuantity) {
      setTimeout(() => addCartItemQuantity(sku, quantity, tally), 100);
      return;
    }

    // If quantity is 0, remove the item from the cart
    if (quantity === 0) {
      return await removeCartItem(sku);
    }

    try {
      // Prepare payload for creating/updating cart item
      const itemsInCart = await getPhysicalItems();
      const item = itemsInCart.find((i) => i.sku === sku);
      const lineId = item?.id;

      const payload = {
        line_id: lineId || undefined,
        sku,
        quantity,
        tally: tally || undefined,
      };

      // Call the API to create/update the cart item
      const { data: cartData, error } = await useAsyncData("createCartItem", () =>
        useSdk().mkmAPI.createCartItem(payload),
      );

      useHandleError(error.value);

      state.value.cart = cartData.value ?? state.value.cart;

      await fireAddToCartGAEvent(item, quantity);
    } catch (error) {
      // Handle errors
      middlewareCheck.checkIfSessionRelatedIssue(error);
    } finally {
      // Reset loading state and remove the item from the loading list
      loading.value.changeCartItemQuantity = false;
    }
  };

  interface CartChangeParams {
    sku: string;
    payload: {
      line_id: string;
      sku: string;
      quantity: number;
    };
    itemInCart: any;
    quantity: number;
    currentQuantity: number;
    item: any;
  }

  const handleCartChange = async ({ sku, payload, itemInCart, quantity, currentQuantity, item }: CartChangeParams) => {
    upsertLoadingSku(sku);
    loading.value.changeCartItemQuantity = true;

    const hasDecreased = quantity < currentQuantity;
    const response = await useAsyncData(itemInCart ? "updateCartItem" : "createCartItem", () =>
      itemInCart ? useSdk().mkmAPI.updateCartItem(payload) : useSdk().mkmAPI.createCartItem(payload),
    );
    state.value.cart = response.data.value;

    if (hasDecreased) {
      await fireRemoveFromCartGAEvent(item, quantity);
      return;
    }

    await fireAddToCartGAEvent(item, quantity);
  };

  const cleanupAfterCartChange = async (sku: string, tally?: string) => {
    loading.value.changeCartItemQuantity = false;
    removeLoadingSku(sku);
    if (tally) {
      await fetchCart();
    }
  };

  const changeItemQuantity = async (sku: string, quantity: number, tally?: string | undefined) => {
    const itemsInCart = await getPhysicalItems();
    const itemInCart = findItemInCart(itemsInCart, sku);
    const item = findItem(itemsInCart, sku);
    const lineId = item?.id;
    const currentQuantity = itemInCart ? itemInCart.quantity : 0;

    if (quantity === 0) {
      return removeCartItem(sku);
    }

    const payload = {
      line_id: lineId as string,
      sku,
      quantity,
      tally: tally || undefined,
    };

    try {
      await handleCartChange({
        sku,
        payload: payload,
        itemInCart,
        quantity,
        currentQuantity,
        item,
      });
    } catch (error) {
      middlewareCheck.checkIfSessionRelatedIssue(error);
    } finally {
      cleanupAfterCartChange(sku, tally);
    }
  };

  return {
    fetchCart,
    addCartItemQuantity,
    removeCartItem,
    changeItemQuantity,
    ...toRefs(state.value),
  };
};

export default useCart;
