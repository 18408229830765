<template>
  <Slider
    v-if="image.image.length > 0"
    :slides-per-view="1"
    :space-between="0"
    :draggable="true"
    :autoplay="false"
    display-pagination-dots
    custom-dots
    custom-dots-theme="orange-gray"
    :items="reversedImages"
    class="!w-full flex sm:hidden flex-wrap justify-center"
  >
    <swiper-slide
      v-for="(galleryImage, index) in reversedImages"
      :key="index"
      :data-test-id="`GalleryItem-${index}`"
      class="m-auto bg-white !w-[100vw] md:w-full !flex justify-center items-center"
    >
      <Stack class="relative w-full aspect-square" align="center" justify="center">
        <NuxtImg class="mobile-image w-full h-full object-contain" :src="galleryImage.url_standard" format="webp" />
        <Icon
          name="zoom-in"
          class="absolute top-xs right-xs cursor-pointer p-[10px] bg-mkm-blue-default hover:bg-mkm-blue-light rounded-md text-white"
          :size="40"
          @click="openZoomModal(image.image.length - 1 - index)"
        />
      </Stack>
    </swiper-slide>
  </Slider>

  <!-- Web -->
  <Stack v-if="image.image.length > 0" direction="col" gap="xs" class="!hidden sm:!flex w-full">
    <div class="relative w-full bg-white aspect-square flex items-center justify-center">
      <NuxtImg
        preload
        class="max-w-full max-h-full"
        :src="image.image[image.image.length - 1 - currentImageIndex].url_zoom"
        sizes="1vw"
        alt=""
      />
      <button aria-label="Click to zoom" @click="openZoomModal()">
        <Icon
          name="zoom-in"
          class="absolute top-sm right-sm cursor-pointer p-xs bg-mkm-blue-default hover:bg-mkm-blue-light rounded-md text-white"
          :size="48"
        />
      </button>
    </div>
    <Stack direction="row" gap="xs" v-if="image && image.image.length > 1" class="hidden sm:flex">
      <div
        v-for="(galleryImage, index) in reversedImages"
        :key="index"
        :class="[
          'w-[82px] h-[82px] bg-white flex items-center justify-center relative',
          'after:absolute after:top-none after:left-none after:w-full after:h-full after:bg-charcoal-darker after:cursor-pointer',
          'after:opacity-0 after:hover:opacity-30 after:transition-all after:duration-200 after:ease-in-out',
          { 'border-md border-mkm-blue-light': currentImageIndex === index },
        ]"
        @click="currentImageIndex = index"
      >
        <NuxtImg :key="index" :src="galleryImage.url_thumbnail" class="max-h-[80px] object-contain" />
      </div>
    </Stack>
  </Stack>

  <Stack class="w-full aspect-square" v-else>
    <NuxtImg :src="IMAGES.PLACEHOLDER" sizes="1vw" />
  </Stack>

  <ProductZoomer
    v-if="showOverlay"
    :images="reversedImages"
    @close="showOverlay = false"
    :currentImageIndex="currentImageIndex"
  />
</template>

<script lang="ts" setup>
import { SwiperSlide } from "swiper/vue";
import { IMAGES } from "@/constants/images";

const image = defineProps(["image"]);

const showOverlay = ref<boolean>(false);
const currentImageIndex = ref<number>(0);

const reversedImages = computed(() => image.image.slice().reverse());

const openZoomModal = (index?: number) => {
  showOverlay.value = true;
  if (index) currentImageIndex.value = index;
};
</script>
