<template>
  <Drawer position="left" v-show="navigation" :is-open="navigation" :hide-scroll="false">
    <template #header>
      <button @click="setNavigation()" class="text-charcoal-default justify-self-end">
        <Icon name="plus-thick" :size="16" filled class="text-charcoal-default rotate-45" />
        <p class="sr-only">Close Navigation Menu</p>
      </button>
    </template>

    <nav class="relative overflow-hidden" ref="menus">
      <section v-for="(item, index) in items" :key="index" class="h-fit w-full overflow-x-hidden">
        <Heading
          display-font
          weight="semi"
          size="xs"
          tag="h3"
          uppercase
          class="px-xs pt-md mb-2xs text-charcoal-default -tracking-[0.04em]"
          >{{ item.title }}</Heading
        >
        <div v-for="(level1, level1Index) in item.menus" :key="`${level1.name}-${level1Index}`">
          <div class="h-full w-full hover:bg-grey-default ui-px-xs">
            <MenuLink
              :is-link="level1.children?.length > 0"
              :name="level1.name"
              :url="level1.url"
              @update-menu="setOpenMenu(`${item.title}-${level1.name}`, '')"
              data-test-id="category-level-1"
            />

            <MenuLayer
              level="1"
              :is-open="openMenu === `${item.title}-${level1.name}` || setPrev === `${item.title}-${level1.name}`"
            >
              <MenuHeader
                :url="level1.url"
                :name="level1.name"
                @reset-menu="setOpenMenu('', '')"
                :data-test-id="openMenu === `${item.title}-${level1.name}` ? 'currentCategoryTitle' : ''"
              />
              <div
                v-for="(level2, level2Index) in level1.children"
                :key="`${level2.name}-${level2Index}`"
                class="hover:bg-grey-default ui-px-xs overflow-hidden"
              >
                <MenuLink
                  :is-link="level2.children?.length > 0"
                  :name="level2.name"
                  :url="level2.url"
                  @update-menu="setOpenMenu(`${level2.name}-${level1.name}`, `${item.title}-${level1.name}`)"
                  :data-test-id="
                    openMenu === `${item.title}-${level1.name}` || setPrev === `${item.title}-${level1.name}`
                      ? 'category-level-2'
                      : ''
                  "
                />

                <MenuLayer level="2" :is-open="openMenu === `${level2.name}-${level1.name}`">
                  <MenuHeader
                    :url="level2.url"
                    :name="level2.name"
                    @reset-menu="setOpenMenu(setPrev, setPrev)"
                    :data-test-id="openMenu === `${level2.name}-${level1.name}` ? 'currentCategoryTitle' : ''"
                  />
                  <div
                    v-for="(level3, level3Index) in level2.children"
                    :key="`${level3.name}-${level3Index}`"
                    class="hover:bg-grey-default ui-px-xs"
                  >
                    <MenuLink
                      :is-link="level3.children?.length > 0"
                      :name="level3.name"
                      :url="level3.url"
                      :data-test-id="openMenu === `${level2.name}-${level1.name}` ? 'category-level-3' : ''"
                    />
                  </div>
                </MenuLayer>
              </div>
            </MenuLayer>
          </div>
        </div>
      </section>
    </nav>
  </Drawer>
</template>

<script lang="ts" setup>
import type { Component, Content, Page } from "@bloomreach/spa-sdk";
import { FormattedMenuItem, MenuItem } from "./menuTypes";
import { removeTrailingSlash } from "mkm-avengers";

interface ContentWithModel extends Content {
  model: any;
}

type Menu = {
  title: string;
  menus: any[];
};

const props = defineProps<{ component: Component; page: Page }>();
const { component } = toRefs(props);

const { menu: menuRef } = component.value.getModels();
const menu = props.page.getContent(menuRef);
const shopByRoomMenuItems = (menu as ContentWithModel)?.model?.data?.siteMenuItems;

const menus = ref<HTMLElement | null>(null);
const items = ref<Menu[]>([]);
const openMenu = ref<string>("");
const setPrev = ref<string>("");

const { setNavigation, navigation } = useUIState();
const { data } = useCategoryTree();

const setOpenMenu = (menu: string, prev: string) => {
  openMenu.value = menu;
  setPrev.value = prev;

  setTimeout(() => {
    if (menus?.value) {
      menus.value.scrollIntoView({ behavior: "smooth" });
    }
  }, 200);
};

const formatMenuItem = (item: MenuItem, type: "shop-by-room" | "shop-by-build"): FormattedMenuItem => {
  const isShopByRoom = type === "shop-by-room";
  const { name, links, url, childMenuItems, children } = item;

  const formattedChildren = isShopByRoom
    ? childMenuItems?.map((child) => formatMenuItem(child, type)) || []
    : children?.map((child) => formatMenuItem(child, type)) || [];

  const formattedUrl = removeTrailingSlash(isShopByRoom ? links?.site?.href : url);

  return { name, children: formattedChildren, url: formattedUrl };
};

const formatShopByRoomMenuItems = (menuItems: MenuItem[]): FormattedMenuItem[] =>
  menuItems.map((item) => formatMenuItem(item, "shop-by-room"));

const formatShopByBuildMenu = (data: MenuItem[]): FormattedMenuItem[] =>
  data.map((item) => formatMenuItem(item, "shop-by-build"));

items.value = [
  { title: shopByRoomMenuItems[0].name, menus: formatShopByRoomMenuItems(shopByRoomMenuItems[0].childMenuItems) },
  { title: "Shop By Build", menus: formatShopByBuildMenu(data.value || []) },
  { title: shopByRoomMenuItems[1].name, menus: formatShopByRoomMenuItems(shopByRoomMenuItems[1].childMenuItems) },
];
</script>
