<template>
  <BaseCard
    variant="product"
    class="ui-w-full ui-h-full ui-p-xs sm:ui-p-none ui-relative"
  >
    <component
      :is="linkComponent ? linkComponent : 'a'"
      :to="linkPath"
      no-prefetch
      class="ui-absolute ui-z-10 ui-right-none ui-left-none ui-bottom-none ui-top-none"
      @click.stop="emit('cardClicked')"
      ><span class="sr-only">{{ content.title }}</span></component
    >
    <Stack
      tag="div"
      direction="row"
      gap="xs"
      :breakpoints="{ direction: { sm: 'col' } }"
      class="ui-w-full ui-h-full"
    >
      <Stack
        tag="div"
        direction="col"
        gap="xs"
        align="center"
        class="sm:ui-w-full"
      >
        <ProductCardImage
          :image="content.image"
          :desktopImage="content.desktopImage"
          :alt="`${content.title} product image`"
          :greenerOption="greenerOption"
          :roundel="roundel"
          :imageLoading="imageLoading"
          :is-placeholder="isPlaceholder"
          data-test-id="plpProductImage"
          data-fs="plpProductImage"
        />

        <Roundel
          class="sm:ui-hidden"
          v-if="roundel"
          :variant="roundel.variant"
        />
      </Stack>
      <Stack
        tag="div"
        direction="col"
        gap="xs"
        class="ui-text-charcoal-default ui-relative ui-w-full ui-h-full sm:ui-px-xs sm:ui-pb-xs ui-flex-grow"
      >
        <Text
          tag="h2"
          size="sm"
          class="sm:ui-h-[66px]"
          v-if="!isPlaceholder"
          :class="
            isPlaceholder &&
            'ui-animate-pulse ui-bg-grey-light ui-text-grey-light'
          "
          data-test-id="plpProductItemTitle"
          data-fs="plpProductItemTitle"
          >{{
            isPlaceholder ? "Trying to find your products" : content.title
          }}</Text
        >

        <div
          v-if="isPlaceholder"
          class="ui-h-[66px] ui-flex ui-flex-col ui-w-full ui-gap-2xs"
        >
          <div
            class="ui-w-[100%] ui-h-[30%] ui-animate-pulse ui-bg-grey-light"
          />
          <div
            class="ui-w-[70%] ui-h-[30%] ui-animate-pulse ui-bg-grey-light"
          />
        </div>

        <GreenerOption
          class="sm:ui-hidden"
          v-if="greenerOption && greenerOption.variant !== 'none'"
          :variant="greenerOption.variant"
          data-fs="greenerOption"
          data-test-id="greenerOption"
        />

        <div
          :class="[
            isPlaceholder && 'ui-bg-grey-light animate-pulse',
            branchSelected
              ? 'sm:ui-h-[115px] md:ui-h-[95px]'
              : 'sm:ui-h-[40px]',
          ]"
        >
          <Stack
            direction="col"
            v-if="isMultipleUSP && branchSelected"
            gap="xs"
            data-test-id="plpBranchName"
            data-fs="plpBranchName"
          >
            <CardUSP
              :class="isPlaceholder && 'ui-invisible'"
              availability="delivery"
              :branch="usp.branch"
              :branch-number="usp.branchNumber"
            />
            <CardUSP
              :class="isPlaceholder && 'ui-invisible'"
              availability="collect"
              :branch="usp.branch"
              @open:branch-tray="emit('open:branchTray')"
              :availability-reason="usp.availabilityReason"
              :branch-number="usp.branchNumber"
            />
          </Stack>
          <CardUSP
            v-bind="usp"
            :availability-reason="usp.availabilityReason"
            v-else
            @open:dropship="emit('dropship')"
            class="ui-relative ui-z-20"
            data-test-id="plpFindOutMore"
          />
        </div>

        <CardBranchSelect
          v-if="!branchSelected"
          class="ui-relative ui-z-20"
          @card-branch-select="emit('cardBranchSelect')"
        />

        <div
          v-if="branchSelected && usp.availability !== 'outOfStock'"
          :class="isPlaceholder && 'ui-invisible'"
        >
          <Text
            size="sm"
            v-if="signedIn && branchSelected"
            data-test-id="plpUserName"
            data-fs="plpUserName"
          >
            <span class="ui-font-bold"> {{ userName }}</span> your price is
          </Text>

          <button
            v-else-if="!signedIn"
            class="ui-relative ui-z-20"
            @click="emit('cardSignIn')"
            data-test-id="plpSignInBtn"
            data-fs="plpSignInBtn"
          >
            <Text size="sm">
              <span
                class="ui-font-bold ui-text-mkm-blue-light"
                data-test-id="signInBtn"
              >
                Sign in</span
              >
              for your account price
            </Text>
          </button>

          <Stack
            tag="div"
            gap="2xs"
            justify="start"
            class="ui-w-full"
            align="baseline"
          >
            <Heading
              size="sm"
              tag="h3"
              class="sm:ui-text-xl sm:ui-leading-xl ui-w-fit"
              data-test-id="plpProductPrice"
              data-fs="plpProductPrice"
              >£{{ content.priceEach.toFixed(2) }}</Heading
            >
            <Text
              tag="span"
              size="caption"
              weight="bold"
              class="ui-w-fit"
              data-test-id="offerUnit"
              >{{ content.unit }}
            </Text>
            <Text
              tag="span"
              size="caption"
              class="ui-w-fit"
              data-test-id="plpVAT"
              data-fs="plpVAT"
            >
              {{ content.vat ? "inc." : "ex." }}
              <Text tag="span" size="caption" uppercase>Vat</Text>
            </Text>
          </Stack>

          <Text
            tag="span"
            size="caption"
            v-if="content.offerPrice"
            :class="content.offerPrice === content.priceEach && 'ui-invisible'"
            data-test-id="plpProductPriceEach"
            data-fs="plpProductPriceEach"
            >£{{ content.offerPrice.toFixed(2) }}
            {{ content.offerPriceEach }}</Text
          >
        </div>

        <Stack
          tag="div"
          gap="none"
          direction="col"
          class="ui-w-full ui-relative ui-z-20"
          :class="isPlaceholder && 'ui-invisible'"
          v-if="
            !isTally &&
            !isEnquire &&
            branchSelected &&
            usp.availability !== 'outOfStock'
          "
        >
          <CardQuantityControl
            @update:quantity="(qty) => updateTotal(qty)"
            :productID="productID"
            :quantity="content.quantity"
            class="ui-mb-xs"
            data-test-id="plpProductItemQuantity"
            data-fs="plpProductItemQuantity"
          />

          <Button
            data-testid="add-to-basket"
            size="md"
            variant="primary"
            class="ui-w-full ui-justify-center ui-mt-auto"
            @click="emit('addItemToBasket', { quantity: quantity, productID })"
            data-test-id="plpAddToBasketBtn"
            data-fs="plpAddToBasketBtn"
            >Add To Basket</Button
          >
        </Stack>

        <Button
          data-testid="is-tally"
          size="md"
          variant="secondary"
          class="ui-w-full ui-justify-center ui-mt-auto ui-z-10"
          v-if="isTally && branchSelected"
          @click="emit('cardIsTallyProduct')"
          >Choose Quantity</Button
        >
        <Button
          data-testid="is-enquire"
          size="md"
          variant="secondary"
          class="ui-w-full ui-justify-center ui-mt-auto"
          v-if="isEnquire && branchSelected"
          @click="emit('cardEnquireProduct')"
          >Enquire About This Item</Button
        >
      </Stack>
    </Stack>
  </BaseCard>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import Button from "../Global/Button/Button.vue";
import GreenerOption from "../GreenerOption/GreenerOption.vue";
import Stack from "../Layout/Stack/Stack.vue";
import Roundel from "../Roundel/Roundel.vue";
import Heading from "../Typography/Heading/Heading.vue";
import Text from "../Typography/Text/Text.vue";
import BaseCard from "./BaseCard.vue";
import CardBranchSelect from "./CardBranchSelect.vue";
import CardQuantityControl from "./CardQuantityControl.vue";
import CardUSP from "./CardUSP.vue";
import ProductCardImage from "./ProductCardImage.vue";
import { ProductCardProps } from "./types";

const props = defineProps<ProductCardProps>();

const isMultipleUSP = computed(() => {
  return (
    props.usp.availability === "collect" ||
    props.usp.availability === "delivery"
  );
});

const emit = defineEmits<{
  (event: "cardBranchSelect"): void;
  (event: "cardSignIn"): void;
  (
    event: "addItemToBasket",
    payload: { quantity: number; productID: string },
  ): void;
  (event: "cardEnquireProduct"): void;
  (event: "cardIsTallyProduct"): void;
  (event: "dropship"): void;
  (event: "open:branchTray"): void;
  (event: "cardClicked"): void;
}>();

const quantity = ref<number>(props.content.quantity);

const updateTotal = (qty: number) => {
  quantity.value = qty;
};
</script>
