<template>
  <section data-fs="plpFacets" data-test-id="plpProductFilters">
    <CategoryDetails v-if="!isFilterTray" />

    <Accordion v-if="totalFoundItems > 0">
      <AccordionItem
        v-if="selectedFacets === 0 && categoriesEnriched && !route.path.includes('search')"
        :id="`${isFilterTray ? 'filter-tray-' : ''}categoryFilter`"
        label="Categories"
        :is-filter="true"
        :border-top="false"
        open-multiple
        initially-expanded
      >
        <Stack tag="ul" gap="2xs" direction="col" class="pb-sm">
          <li v-for="category in categoriesEnriched" :key="category.id" class="w-full">
            <NuxtLink :to="category.url" class="flex items-center justify-start gap-2xs w-full">
              <Icon name="nav-left" :size="12" :class="category.parent && !category.active ? 'visible' : 'invisible'" />
              <Text tag="span" size="md" :weight="category.active ? 'bold' : 'semi'">
                {{ category.name }}
                <span v-if="!category.parent || category.active">({{ category.count }})</span>
              </Text>
            </NuxtLink>
          </li>
        </Stack>
      </AccordionItem>

      <AccordionItem
        v-if="selectedBranch"
        :id="`${isFilterTray ? 'filter-tray-' : ''}priceFilter`"
        label="Price"
        :is-filter="true"
        :border-top="selectedFacets === 0 && (categoriesEnriched?.length ?? 0) > 0"
        open-multiple
      >
        <CategoryPriceFilter @update:price="(value) => setRangeButton(value)" :is-filter-tray="isFilterTray" />
      </AccordionItem>

      <AccordionItem
        v-for="(commonFacet, i) in commonFacets"
        :id="`${isFilterTray ? 'filter-tray-' : ''}${commonFacet.name}-${i}`"
        :label="commonFacet.name && $ft(`${commonFacet.name}`, commonFacet.name)"
        :key="`${commonFacet.name}-${i}`"
        :is-filter="true"
        border-top
        open-multiple
      >
        <Stack
          tag="ul"
          direction="col"
          gap="xs"
          class="pb-sm"
          :data-test-id="`${isFilterTray ? 'mobile-' : 'desktop-'}${commonFacet.name}`"
        >
          <Stack tag="li" v-for="(facet, facetIndex) in commonFacet.values" :key="`${facet.name}-${facetIndex}`">
            <Checkbox
              :id="`${commonFacet.id}-${facet.id}-${isFilterTray ? 'filter-tray' : 'filter'}`"
              class="px-[6px]"
              :model-value="filters[commonFacet.id] && isSelected(commonFacet.id, facet.id)"
              :value="filters[commonFacet.id] && isSelected(commonFacet.id, facet.id)"
              :name="`${commonFacet.id}-${facet.id}-${isFilterTray ? 'filter-tray' : 'filter'}`"
              :label="`${facet.name} (${facet.count})`"
              @update:model-value="(value: any) => onFacetChange(value, commonFacet.id, facet.id)"
            />
          </Stack>
        </Stack>
      </AccordionItem>
    </Accordion>
  </section>
</template>

<script lang="ts" setup>
const { filters, change } = useFilters();
import sortBy from "lodash.sortby";
import { productFiltersTranslation } from "~/utils/productUnitTranslation";

const route = useRoute();
const { selectedBranch } = useBranches();

import type { AppliedFilterProps, Category, Facet } from "./categoryTypes";
import { removeTrailingSlash } from "mkm-avengers";

const props = defineProps<AppliedFilterProps>();

const $ft = (key: any, fallback: string) => {
  if (productFiltersTranslation(key)) return productFiltersTranslation(key);

  return fallback.replace(/[/_-]/g, " ").replace(/^\w/, (match: string) => match.toUpperCase());
};

const categories = computed(() => (props.facets ?? []).find((facet) => facet.id === "category")?.values);

const categoriesEnriched: ComputedRef<Category[] | undefined> = computed(() => {
  return categories?.value?.map((category) => {
    const selectedCategory = filters.value["category"]?.find((it: any) => it.id === category.id);
    return {
      ...category,
      id: category.id,
      parentId: category.parentId,
      active: category.id === props.activeCategoryId && filters.value["category"].length === 0,
      parent: category.parentId !== props.activeCategoryId,
      selected: selectedCategory ? { id: selectedCategory.id } : undefined,
      name: category.name,
      url: removeTrailingSlash(category.url),
    };
  });
});

const onFacetChange = (value: boolean, facetId: any, valueId: any) => {
  change(value, { id: facetId, value: valueId });
};

const selectedFacets: ComputedRef<number> = computed(() => {
  let facetCount = 0;

  for (let filterKey in filters.value) {
    if (filterKey === "category" || filterKey === "price") {
      continue;
    }

    for (let facet in filters.value[filterKey]) {
      if (facet.length > 0) {
        facetCount++;
      }
    }
  }

  return facetCount > 0 ? 1 : 0;
});

const commonFacets: ComputedRef<Facet[]> = computed(() => {
  return props.facets
    .filter((facet) => facet.id !== "category")
    .map((facet) => {
      return facet.isRangeFilter
        ? facet
        : {
            ...facet,
            values: sortBy(facet.values, "name"),
          };
    });
});

const isSelected = (facetId: string, value: string): boolean => {
  return filters.value[facetId]?.some((selectedValue: string) => selectedValue === value) || false;
};

const setRangeButton = (params: { from: string; to: string }) => {
  const facets = [];
  if (params.from) {
    facets.push({ id: "price", valueId: "from", value: params.from });
  }
  if (params.to) {
    facets.push({ id: "price", valueId: "to", value: params.to });
  }

  // @ts-ignore
  change(true, ...facets);
};
</script>
