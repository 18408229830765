<template>
  <Stack justify="center" align="center" :id="anchorTag" class="py-lg">
    <iframe
      title="iframe-container"
      class="mx-auto"
      v-if="src"
      :src="src"
      frameborder="0"
      :width="width || '100%'"
      :height="height || '100%'"
      scrolling="no"
    />
  </Stack>
</template>

<script lang="ts" setup>
import { useContentHelpers } from "../../composables/UseContent/helpers";
import type { Component, Page } from "@bloomreach/spa-sdk";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const data = computed(() => useContentHelpers(page.value, component.value).data);

const src = computed(() => data.value.src);
const width = computed(() => data.value.width);
const height = computed(() => data.value.height);
const anchorTag = computed(() => data.value.anchorTag);
</script>
