<template>
  <section class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-sm max-w-[1564px] px-sm mx-auto my-lg">
    <NuxtLink
      v-for="(kitchen, index) in kitchens"
      :key="index"
      :to="kitchen.link?.href"
      class="shadow-xs block bg-white rounded-sm"
    >
      <NuxtImg
        v-if="kitchen.image"
        :src="kitchen.image"
        :alt="kitchen.alt"
        loading="lazy"
        provider="cloudflare"
        sizes="lg:510px xl:820px"
        class="object-cover w-full h-[20rem] lg:h-[25rem] rounded-t-sm"
      />
      <div :class="['mt-sm mb-xs px-2xs text-charcoal-default']">
        <Heading size="xs" class="text-charcoal-default text-center">
          {{ kitchen.title }}
        </Heading>
        <Text size="md" weight="semi" class="text-center mt-2xs line-clamp-2 text-ellipsis text-sm">
          {{ kitchen.description }}
        </Text>
      </div>

      <section v-if="kitchen.colors.length > 0" class="mx-auto w-fit mb-sm">
        <div
          v-for="color in kitchen.colors"
          :key="color"
          :class="['rounded-round p-xs w-fit inline-block mx-3xs']"
          :style="{ backgroundColor: color }"
        />
      </section>
    </NuxtLink>
  </section>
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useKitchenList } from "../../composables/UseContent/useKitchenList";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const kitchens = computed(() => useKitchenList(page.value, component.value).kitchens);
</script>
