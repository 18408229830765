<template>
  <LazyDrawer position="right" :is-open="loginTray" :hide-scroll="false">
    <template #header>
      <Stack tag="div" align="start" justify="end">
        <button @click="setLoginTray">
          <span class="sr-only">Close login tray</span>
          <Icon name="plus-thick" :size="16" class="rotate-45" />
        </button>
      </Stack>

      <Heading tag="h3" size="sm" class="mt-xs">Sign in</Heading>
    </template>

    <aside class="pt-md px-sm">
      <Stack tag="div" direction="col">
        <Banner v-if="showFormErrorMessage" variant="error">
          We’re sorry but we don’t recognise the email address or password you’ve entered. Please check your details and
          try again.
        </Banner>

        <Input
          id="email"
          data-test-id="tray-email"
          size="md"
          :model-value="email"
          label="Email Address"
          data-validation-rule="email"
          @update:input-value="email = String($event)"
          @blur="vee.validateSingleField($event)"
          :error="vee.fieldHasError('email').value"
          :message-text="vee.fieldError('email').value"
        />

        <Input
          id="password"
          data-test-id="tray-password"
          size="md"
          :model-value="password"
          label="Password"
          is-password
          link-text="Forgotten password?"
          link-href="/forgotten-password"
          link-class="text-mkm-blue-light text-sm"
          :link-tag="NuxtLink"
          @link:click="setLoginTray"
          @update:input-value="password = String($event)"
          data-validation-rule="password"
          @blur="vee.validateSingleField($event)"
          :error="vee.fieldHasError('password').value"
          :message-text="vee.fieldError('password').value"
        />

        <div class="w-full">
          <Button
            :disabled="disabledLogin"
            data-test-id="tray-signInButton"
            data-fs="tray-signInButton"
            size="lg"
            variant="secondary"
            @click="handleLogin"
            class="min-w-full justify-center mb-xs"
          >
            SIGN IN
            <Spinner v-show="isLoggingIn" />
          </Button>

          <NuxtLink :to="ROUTES.CREATE_ACCOUNT" class="w-full block" @click="setLoginTray">
            <Text tag="span" size="sm" align="center" class="text-mkm-blue-light w-full block">
              Don’t have an account? Create one now
            </Text>
          </NuxtLink>
        </div>
      </Stack>
    </aside>

    <template #footer>
      <Stack align="center" justify="between" class="w-full">
        <Button variant="neutral" size="md" class="!w-full" @click="setLoginTray" :disabled="isLoggingIn">
          Cancel
          <span class="sr-only">Login Tray</span>
        </Button>
      </Stack>
    </template>
  </LazyDrawer>
</template>

<script setup lang="ts">
import { ROUTES } from "@/helpers/routes/routes";

const { loginTray, setLoginTray } = useUIState();
const { login, user } = useUser();
const { selectBranchFromCurrentUser } = useBranchCurrentUser();
const vee = useVeeValidate();
const NuxtLink = resolveComponent("NuxtLink");

const showFormErrorMessage = ref<boolean>(false);
const email = ref<string>("");
const password = ref<string>("");
const isLoggingIn = ref<boolean>(false);

const disabledLogin = computed(
  () => vee.fieldHasError("email").value || vee.fieldHasError("password").value || isLoggingIn.value,
);

const handleLogin = async () => {
  await vee.validateAllFields({ email: email.value, password: password.value });
  if (Object.keys(vee.errors.value).length > 0) {
    return;
  }

  isLoggingIn.value = true;

  await nextTick(async () => {
    try {
      await login({
        email: email.value,
        password: password.value,
        isCheckout: false,
      });
    } catch (error) {
      if (error) {
        showFormErrorMessage.value = true;
      }
    }
  });

  if (user.value) {
    email.value = "";
    password.value = "";

    await nextTick(async () => {
      await selectBranchFromCurrentUser();
    });

    setLoginTray();

    return;
  }

  isLoggingIn.value = false;
  showFormErrorMessage.value = true;
};
</script>
