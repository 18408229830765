import { type Page } from "@bloomreach/spa-sdk";
import { getFileName } from "mkm-avengers";

export function useGallery(page: Page, component: any) {
  const { getSrcOf } = useContentHelpers(page, component);

  const { gallery } = component.getModels();
  const data = (page.getContent(gallery) as any)?.model?.data;

  return {
    images: data?.images.map((imgRef: any) => {
      const imageSrc = getSrcOf(imgRef);
      return {
        src: imageSrc,
        alt: getFileName(imageSrc),
      };
    }),
    breakpoints: {
      320: {
        slidesPerView: 1.1,
      },
      520: {
        slidesPerView: 1.4,
      },
      768: {
        slidesPerView: 2.2,
      },
      960: {
        slidesPerView: 3.1,
      },
      1024: {
        slidesPerView: 3.2,
      },
    },
  };
}
