/* eslint-disable sonarjs/cognitive-complexity */
import { BreadcrumbItemProps } from "@/components/UI/Breadcrumbs/breadcrumbTypes";
import { checkIfFilterIsSelected, combineAllFacets } from "@/helpers/category";
import { capitalizeFirstLetter, capitalizeWords, findByUrl } from "mkm-avengers";
import { checkForPaginationInURL, checkIfItsBrowserStepBack } from "./categoryPageHelpers";
import type { UseCategoryPageState } from "./categoryPageTypes";

const useCategoryPage = () => {
  const state = useState<UseCategoryPageState>(`useCategoryPage`, () => ({
    isCatLanding: false,
    categoryName: "",
    currentPage: 1,
    itemsPerPage: 20,
    facets: [],
    filteredParams: {},
    currentCategoryBrId: "",
    loadingItems: false,
    branchInitialLoad: false,
    currentSearchPage: 1,
  }));

  const { data: categoryBCData, fetchItemsByCategory, bigCommerceCategory, search } = useCategory();

  const { convertToBRData, filters } = useFilters();

  const { routerHistory } = useRouterHistory();
  const { data: categoryTreeItems } = useCategoryTree();

  const { selectedBranch } = useBranches();

  const { sortTerm, sortBy } = useSortBy();
  const route = useRoute();
  const router = useRouter();

  const currentPagePath = url(route.path);
  const categoryBC = findByUrl(categoryTreeItems.value ?? [], currentPagePath);
  const routeSlug = route.query.q === undefined ? "" : decodeURIComponent(route.query.q as string);

  const onSortBy = async (value: string, searchTemplate = false) => {
    sortBy(false, value);
    if (searchTemplate) {
      await search({
        query: routeSlug as string,
        filterValues: convertToBRData(),
        sortTerm: sortTerm.value,
        itemsPerPage: state.value.itemsPerPage,
        offset:
          state.value.currentSearchPage === 1 ? 0 : (state.value.currentSearchPage - 1) * state.value.itemsPerPage,
      });
      return;
    }
    await fetchItemsByCategory({
      categoryId: state.value.currentCategoryBrId,
      itemsPerPage: state.value.itemsPerPage,
      offset: 0,
      onlyTotal: false,
      filterValues: convertToBRData(),
      sortTerm: sortTerm.value,
    });
  };

  const findById = (dataId: any) => {
    let result;

    const iter = (a: any) => {
      if (Number(a.id) === Number(dataId)) {
        result = a;
        return true;
      }
      return Array.isArray(a.children) && a.children.some((element: any) => iter(element));
    };

    categoryTreeItems?.value?.some((element: any) => iter(element));
    return result;
  };

  // @ts-ignore
  const checkIsValidFacetAndSetUrl = (dataBR: any) => {
    let categoryIndex;
    const updatedCategoryFacets: any[] = [];
    const modifiedFacets = [];
    const categoryFacets = dataBR.filter((el: { id: string }, index: any) => {
      if (el.id === "category") {
        categoryIndex = index;
        return true;
      } else {
        modifiedFacets.push(el);
      }

      return false;
    });

    if (categoryFacets.length > 0) {
      categoryFacets[0].values.forEach((el: { id: any }) => {
        const existingCategory = findById(el.id);
        if (existingCategory) {
          // @ts-ignore
          updatedCategoryFacets.push({ ...el, url: existingCategory.url });
        }
      });
    }

    // @ts-ignore
    const copyOfCategoryFacets = { ...dataBR[categoryIndex] };
    delete copyOfCategoryFacets.values;
    copyOfCategoryFacets.values = updatedCategoryFacets;

    modifiedFacets.push(copyOfCategoryFacets);

    return modifiedFacets;
  };

  const generateQueryParams = (page: any) => {
    state.value.filteredParams = checkIfFilterIsSelected(filters);
    const p = page !== 1 && page !== undefined ? page : 1;
    return new URLSearchParams({
      ...state.value.filteredParams,
      p: p.toString(),
      sort_by: sortTerm.value,
      sort_value: route.query.sort_value?.toString() || "",
    });
  };

  const generatePaginationUrl = (page: any) => {
    if (process.client) {
      window.addEventListener("popstate", () => {
        if (!window?.location?.href?.includes("p=")) {
          router.push(window.location.pathname);
        }
      });

      const baseURL = route.fullPath;
      let url;
      let queryParams;

      state.value.currentPage = page;

      if (page !== 1 && page !== undefined) {
        queryParams = generateQueryParams(page);
        url = `${checkIfItsBrowserStepBack(baseURL)}?${queryParams}`;
      } else {
        queryParams = generateQueryParams(1);
        url = `${checkIfItsBrowserStepBack(baseURL)}?${queryParams}`;

        if (url.endsWith("?")) {
          url = url.slice(0, 0);
        }
      }

      if (baseURL.includes("gad")) {
        router.push(url + "&gad=1");
      } else {
        router.push(url);
      }
    }
  };

  const generateSearchPaginationUrl = () => {
    // An Explanation
    // Since we need to add a page info to the URL, so that based on this URL
    // We can know where to return the user when they press the BACK button of the browser
    // We create a baseURL with the parameters "q" and "p", which indicate the searched word and the page
    // Generate the parameters using the JS function -  URLSearchParams - to be in the form of a query parameters

    const baseURL = "/search";
    const q = route.query.q;
    const p = state.value.currentSearchPage;

    // "P" takes the value of the currentPage that is defined in the fetchNewPageOnSearchPage function
    // This value actually comes from the v-pagination in the "ProductsGrid" component
    state.value.filteredParams = checkIfFilterIsSelected(filters);

    const queryParams = new URLSearchParams({
      q,
      ...state.value.filteredParams,
      p,
      sort_by: sortTerm.value,
      sort_value: route.query.sort_value || "",
    });
    const url = `${baseURL}?${queryParams}`;

    router.push(url);
  };

  const fetchNewPageOnSearchPage = async (page?: any) => {
    if (page) {
      state.value.currentSearchPage = page;
    }

    // See the explanation in the "generatePaginationUrl" function.
    generateSearchPaginationUrl();
    await search({
      query: routeSlug as string,
      filterValues: convertToBRData(),
      sortTerm: sortTerm.value,
      itemsPerPage: state.value.itemsPerPage,
      offset: state.value.currentSearchPage === 1 ? 0 : (state.value.currentSearchPage - 1) * state.value.itemsPerPage,
    });

    const categories = combineAllFacets(
      categoryBCData.value?.facetResult?.fields || [],
      categoryBCData.value?.facetResult?.ranges || [],
    );

    // @ts-ignore
    state.value.facets = checkIsValidFacetAndSetUrl(categories) as any[];
  };

  const generateBreadcrumbs = () => {
    const breadcrumbs: BreadcrumbItemProps[] = [];

    let rootUrl = "/";

    Object.keys(route.params).forEach((key, index) => {
      if (route.params[key]) {
        rootUrl =
          index === 0
            ? rootUrl.concat("", route.params[key].toString())
            : rootUrl.concat("/", route.params[key].toString());

        breadcrumbs.push({
          title: capitalizeFirstLetter(
            Array.isArray(route.params[key]) ? route.params[key][0].toString() : route.params[key].toString(),
          ).replaceAll(/-/g, " "),

          href: `/category${rootUrl}`,
        });
      }
    });

    return breadcrumbs;
  };

  const generatePageTitle = () => {
    let rootUrl = "/";

    const titles = Object.keys(route.params).reduce(
      (acc, key, index) => {
        if (route.params[key]) {
          rootUrl =
            index === 0
              ? rootUrl.concat("", route.params[key].toString())
              : rootUrl.concat("/", route.params[key].toString());

          const title = capitalizeWords(
            Array.isArray(route.params[key]) ? route.params[key][0].toString() : route.params[key].toString(),
          ).replaceAll(/-/g, " ");

          return [...acc, { title }];
        }
        return acc;
      },
      [] as { title: string }[],
    );

    return [...titles]
      .reverse()
      .map(({ title }) => title)
      .join(" | ");
  };

  const fetchNewPage = async (page?: any) => {
    if (selectedBranch.value) {
      state.value.branchInitialLoad = false;
      generatePaginationUrl(page);
    }

    if (!selectedBranch.value && state.value.branchInitialLoad) {
      generatePaginationUrl(page);
    }

    const baseURL = route.fullPath;
    if (page !== 1 && page !== undefined) {
      state.value.currentPage = page;
    } else {
      // Check if the page is accessed directly in the URL
      // The possible scenario is when the user access (eg: someCategory/cat2/cat3?p=4)

      if (checkForPaginationInURL(baseURL) > 0) {
        state.value.currentPage = page;
      }
    }

    // setIsLoadingPrice(true);
    await nextTick();
    await fetchItemsByCategory({
      categoryId: state.value.currentCategoryBrId,
      itemsPerPage: state.value.itemsPerPage,
      offset: state.value.currentPage === 1 ? 0 : (state.value.currentPage - 1) * state.value.itemsPerPage,
      onlyTotal: false,
      filterValues: convertToBRData(),
      sortTerm: sortTerm.value,
    });

    state.value.branchInitialLoad = true;

    // updateTotalResults();
    const categories = combineAllFacets(
      categoryBCData.value?.facetResult?.fields || [],
      categoryBCData.value?.facetResult?.ranges || [],
    );

    // @ts-ignore
    state.value.facets = checkIsValidFacetAndSetUrl(categories) as any[];
    // setIsLoadingPrice(false);
  };

  const fetchItemsOnFirstMounting = async (categoryId: any, itemsPerPage = 10) => {
    if (route.params.cat2.length === 0 && route.params.cat3.length === 0) {
      return;
    } else {
      state.value.currentPage = 1;
    }

    await fetchItemsByCategory({
      categoryId,
      itemsPerPage,
      offset: state.value.currentPage === 1 ? 0 : (state.value.currentPage - 1) * state.value.itemsPerPage,
      onlyTotal: false,
      filterValues: convertToBRData(),
      sortTerm: sortTerm.value,
    });
    // // updateTotalResults();

    const categories = combineAllFacets(
      categoryBCData.value?.facetResult?.fields || [],
      categoryBCData.value?.facetResult?.ranges || [],
    );

    // // @ts-ignore
    state.value.facets = checkIsValidFacetAndSetUrl(categories) as any[];
  };

  const isCatLandingLayout = () => {
    return (
      bigCommerceCategory.value?.layout_file?.toLowerCase() === "cat_landing" ||
      bigCommerceCategory.value?.layout_file?.toLowerCase() === '["2", "cat_landing"]'
    );
  };

  return {
    onSortBy,
    generateBreadcrumbs,
    isCatLandingLayout,
    fetchItemsOnFirstMounting,
    checkIsValidFacetAndSetUrl,
    fetchNewPageOnSearchPage,
    generatePageTitle,
    fetchNewPage,
    currentPagePath,
    categoryBC,
    ...toRefs(state.value),
  };
};

export default useCategoryPage;
